html, body {
    color: #333;
    padding-bottom: 85px;
}

.navbar-rhodes {
  height: 85px;
  border-bottom: 1px solid #e7e7e7;
  position: relative;
  background: white;

}

nav.navbar-rhodes ul.navbar-nav a {
  color: #1825aa;
  border-style: solid;
  border-width: 0 0 2px 0;
  border-color: #fff; height: 63px;
}

nav.navbar-rhodes ul.navbar-nav a:hover,
nav.navbar-rhodes ul.navbar-nav a:visited,
nav.navbar-rhodes ul.navbar-nav a:focus,
nav.navbar-rhodes ul.navbar-nav a:active {
  background: #fff;
}
nav.navbar-rhodes ul.navbar-nav a:hover {
  border-color: #1825aa;
}
nav.navbar-rhodes li.divider {
  background: #ccc;
}
nav.navbar-rhodes button.navbar-toggle {
  background: #1825aa;
}
nav.navbar-rhodes button.navbar-toggle:hover {
  background: #999;
}
nav.navbar-rhodes button.navbar-toggle > span.icon-bar {
  background: #fff;
}
nav.navbar-rhodes ul.dropdown-menu {
  border: 0;
  background: #fff;
  margin: 4px 0;
  box-shadow: 0 0 4px 0 #ccc;
}
nav.navbar-rhodes ul.dropdown-menu > li > a {
  padding-top: 20px;
}
nav.navbar-rhodes ul.dropdown-menu > li > a:hover {
  background: #1825aa;
  color: #fff;
}

.logo {
  position: relative;
  padding: 0;
  display: block;
  background: url(../images/rhodes-logo.png);
  width: 160px;
  height: 44px;
  margin: 0;
  display: inline-block;
  float: left;
  margin-top: 20px;
}

.rhodes-slogan {
  float: left;
  display: table-cell;
  margin: 0;
  padding: 0;
  padding-top: 46px;
  text-align: center;
  position: relative;
  height: 43px;
  vertical-align: middle;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #1825aa;
  white-space: nowrap;
}

.rhodes-menu-li {
  float: left;
  display: table-cell;
  margin: 0;
  padding: 0;
  padding-top: 20px;
  text-align: center;
}


.rhodes-menu-li a {
  position: relative;
  height: 43px;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  //font-family: "Averta W01 Light1591190", serif; #85c598 -green
  letter-spacing: 0;
  text-transform: uppercase;
  padding: 26px 29px;
  padding: 26px 2.1vw;
  color: #1825aa;
  white-space: nowrap;
  white-space: -moz-nowrap;
  white-space: -nowrap;
  white-space: -o-nowrap;
}

.btn-rhodes {
  //margin: 10px;
  padding: 10px 15px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border: 0;
  background: #1825aa; //#002b59;
  cursor: pointer;
  border-radius: 0;
}

.btn-rhodes:hover {
  background: #00448c;
}

.panel-rhodes {
    margin-bottom: 22px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-rhodes > .panel-heading {
    color: #fff;
    background-color: #1825aa; //#002b59;
    border-color: #1825aa; //#002b59;
    border-radius: 0;
}

.footer {
  //position: absolute;
  //bottom: 0;
  //width: 100%;
  //margin-left: -15px;
  height: 85px;
  background-color: #1825aa;
}

.footer-logo {
  margin-top: 20px;
}

.footer-copy {
  color: #ffffff;
  margin-top: 30px;
}
/*
.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    text-decoration: none;
    background-color: #cccccc;
}*/
